import { useStaticQuery, graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import Layout from "../components/layout"
import SearchResult from "../components/searchResult"

// Add elasticlunr and register our pipeline functions
import elasticlunr from "elasticlunr"
require('../../plugins/my-elasticlunr-plugin/curlyQuoteStraightener')(elasticlunr);
require('../../plugins/my-elasticlunr-plugin/elisionExpander')(elasticlunr);
require('../../plugins/my-elasticlunr-plugin/apostropheEssRemover')(elasticlunr);
require('../../plugins/my-elasticlunr-plugin/apostropheRemover')(elasticlunr);

// Setup a custom pipeline that ignores quote style, punctuation, and expands elisions,
// but does NOT remove apostrophe + s or filter stop words. This gives us an array
// of tokens for phrase comparison.
const phraseTokenizerPipeline = elasticlunr(function() {
  this.pipeline.reset();
  this.pipeline.add(elasticlunr.curlyQuoteStraightener);
  this.pipeline.add(elasticlunr.trimmer);
  this.pipeline.add(elasticlunr.elisionExpander);
  this.pipeline.add(elasticlunr.apostropheRemover);
}).pipeline;

export default () => {
  const data = useStaticQuery(graphql`
    query SearchIndexQuery {
      siteSearchIndex {
        index
      }
    }
  `)
  const searchIndex = elasticlunr.Index.load(data.siteSearchIndex.index)

  // For available options, see http://elasticlunr.com/docs/configuration.js.html
  const elasticlunrConfigurationObject = {
    bool: 'OR',
    // NOTE: If `fields` is present, you MUST explicitly define each field
    // you want to be searched. Any time a new field is added in
    // gatsby-config.js, it must also be defined here with a boost before it
    // will be used in the search.
    fields: {
      slug: { boost: 0 },
      title: { boost: 2 },
      tuneEmojis: { boost: 0 },
      meter: { boost: 1 },
      authors: { boost: 1 },
      tuneNames: { boost: 1 },
      composers: { boost: 1 },
      verse1: { boost: 1 },
      verse2: { boost: 1 },
      verse3: { boost: 1 },
      verse4: { boost: 1 },
      verse5: { boost: 1 },
      verse6: { boost: 1 },
      verse7: { boost: 1 },
      verse8: { boost: 1 },
      verse9: { boost: 1 },
      verse10: { boost: 1 },
      chorus: { boost: 1 },
      refrain: { boost: 1 },
      coda: { boost: 1 },
      sanctus: { boost: 1 },
      bridge: { boost: 1 },
    }
  };
  const elasticlunrConfigurationString = JSON.stringify(elasticlunrConfigurationObject);
  const elasticlunrConfig = new elasticlunr.Configuration(elasticlunrConfigurationString, searchIndex.getFields()).get();

  const [initialized, setInitialized] = useState(false);

  // UI: text input
  const [searchInput, setSearchInput] = useState('');

  // Info about most-recently-executed search: query (search string), tokens, results (with metadata and documents)
  const [currentSearch, setCurrentSearch] = useState(undefined);

  function initialize() {
    // Only do this once.
    if (initialized) {
      return;
    }
    setInitialized(true);

    // Populate the search box from the query string 'q' value.
    const queryStringWithoutQuestionMark = window.location.search.substring(1);
    const queryStringParams = queryStringWithoutQuestionMark.split('&');
    for (var i = 0; i < queryStringParams.length; i++) {
      const [paramName, paramValue] = queryStringParams[i].split('=').map(decodeURIComponent);
      if (paramName == 'q') {
        setSearchInput(paramValue);
        search(paramValue);
      } else {
        document.getElementById('searchInput').focus();
      }
    }
  }
  useEffect(initialize);

  // We're doing this to make the input a "controlled component" for React.
  // Omitting this results in a console error.
  // See https://reactjs.org/docs/forms.html#controlled-components
  function handleChange(event) {
    setSearchInput(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    setInitialized(false);
    if (searchInput.length) {
      // Force a full page navigation to 1) ensure this gets counted by goatcounter and 2) ensure this is in the browser's history.
      window.location.href = `/search/?q=${encodeURI(searchInput)}`;
    }
  }

  function search(query) {
    if (!query) {
      setCurrentSearch(undefined);
      return;
    }

    const rawQueryTokens = elasticlunr.tokenizer(query);
    const phraseQueryTokens = phraseTokenizerPipeline.run(rawQueryTokens);
    const processedQueryTokens = searchIndex.pipeline.run(rawQueryTokens);

    const internalResults = {};

    for (let fieldName in elasticlunrConfig) {
      // Construct an object where we can keep track of how much this field contributed to the overall search results.
      let field = {
        name: fieldName,
        searchResults: searchIndex.fieldSearch(processedQueryTokens, fieldName, elasticlunrConfig)
      };

      // Apply field boost.
      for (let docRef in field.searchResults) {
        field.searchResults[docRef] = field.searchResults[docRef] * elasticlunrConfig[fieldName].boost;
      }

      // Add the field information to the overall query result metadata.
      for (let docRef in field.searchResults) {
        if (!(docRef in internalResults)) {
          internalResults[docRef] = {
            score: 0,
            fields: {}
          };
        }

        internalResults[docRef].score += field.searchResults[docRef];
        internalResults[docRef].fields[fieldName] = {
          score: field.searchResults[docRef]
        };
      }
    }

    // Construct result objects which include the doc itself and the query result metadata (doc, score, fields):
    const results = Object.keys(internalResults)
      .map(docRef => Object.assign({
        doc: searchIndex.documentStore.getDoc(docRef)
      }, internalResults[docRef]));

    // Construct location metadata
    results.forEach(r => {
      const doc = r.doc;
      const highlights = {};
      const getHighlightsForLineOfText = line => {
        const rawTokensWithLocationInfo = [...line.matchAll(/[\w’']+/g)];

        // Look for phrase matches.
        const phraseHighlights = [];
        if (phraseQueryTokens.length > 1) {
          const lineTokens = phraseTokenizerPipeline.run(elasticlunr.tokenizer(line));
          let i = -1;

          while ((i = lineTokens.indexOf(phraseQueryTokens[0], i+1)) !== -1) {
            const tokensToCompare = lineTokens.slice(i, i + phraseQueryTokens.length);
            if (phraseQueryTokens.length === tokensToCompare.length && phraseQueryTokens.every((value, index) => value === tokensToCompare[index])) {
              const rawTokensToHighlight = rawTokensWithLocationInfo.slice(i, i + phraseQueryTokens.length);
              const phraseStartIndex = rawTokensToHighlight[0].index;
              const phraseEndIndex = rawTokensToHighlight[rawTokensToHighlight.length-1].index + rawTokensToHighlight[rawTokensToHighlight.length-1][0].length;

              // boost result score based on phrase match
              r.score += 10;

              phraseHighlights.push({
                matchedText: line.substring(phraseStartIndex, phraseEndIndex),
                kind: 'phrase',
                startIndex: phraseStartIndex,
                endIndex: phraseEndIndex
              });
              i = phraseEndIndex-1;
            }
          }
        }

        // Look for token matches.
        const tokenHighlights = rawTokensWithLocationInfo.map(regexMatch => {
            const original = regexMatch[0];
            const unprocessedLineToken = elasticlunr.tokenizer(original)[0];
            const processedLineToken = searchIndex.pipeline.run([unprocessedLineToken])[0];

            // Skip words which were not searched for.
            if (!processedQueryTokens.includes(processedLineToken)) {
              return null;
            }

            const startIndex = regexMatch.index;
            const endIndex = startIndex + original.length;

            // Skip words which are already highlighted by a phrase.
            for (let i = 0; i < phraseHighlights.length; i++) {
              if (startIndex >= phraseHighlights[i].startIndex &&
                  startIndex <= phraseHighlights[i].endIndex) {
                return null;
              }
            }

            return {
              matchedText: original,
              kind: 'token',
              startIndex,
              endIndex
            }
          })
          .filter(x => Boolean(x)); // exclude nulls

        const highlights = phraseHighlights.concat(tokenHighlights).sort((a, b) => {
          if (a.startIndex < b.startIndex) {
            return -1;
          }
          if (a.startIndex > b.startIndex) {
            return 1;
          }
          return 0;
        });
        
        return highlights;
      }

      Object.keys(r.fields).forEach(key => {
        if (typeof (doc[key]) === 'string') {
          highlights[key] = getHighlightsForLineOfText(doc[key]);
        }
        if (Array.isArray(doc[key])) {
          highlights[key] = doc[key].map(line => getHighlightsForLineOfText(line));
        }
      });

      r.highlights = highlights;
    });

    results.sort(function (a, b) { return b.score - a.score; });

    const currentSearch = {
      query,
      results
    };

    setCurrentSearch(currentSearch);
  };

  const pageTitle = currentSearch ? `Search: ${currentSearch.query}` : 'Search';

  return (
    <Layout>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <h1>Search</h1>
      <form action="#" onSubmit={handleSubmit}>
        <table className="searchBar">
          <tbody>
            <tr>
              <td><input type="text" id="searchInput" value={searchInput} onChange={handleChange} /></td>
              <td><input type="submit" value="Search" disabled={searchInput ? undefined : 'disabled'} /></td>
            </tr>
          </tbody>
        </table>
      </form>

      { currentSearch &&
      <ul className="searchResults">
        {currentSearch.results.map(result => (
          <SearchResult key={result.doc.id} searchQuery={currentSearch.query} result={result} />
        ))}
      </ul>
      }
    </Layout>
  )
}
