import { Link } from "gatsby"
import React from "react"

const SearchResult = (props) => {
  const { result } = props;

  return (
    <li>
      <h2><Link to={result.doc.slug}>{result.doc.title}</Link>{result.doc.tuneEmojis ? " " + result.doc.tuneEmojis : ""}</h2>
      {
        result.fields.title && Object.keys(result.fields).length === 1 &&
        <div><h3>Title:</h3> {renderWithHighlights(result, 'title')}</div>
      }
      {
        result.fields.meter &&
        <div><h3>Meter:</h3> {renderWithHighlights(result, 'meter')}</div>
      }
      {
        result.fields.authors &&
        <div><h3>Author{result.doc.authors.length > 1 ? 's' : ''}:</h3> {renderWithHighlights(result, 'authors', { forceSingleLine: true })}</div>
      }
      {
        result.fields.composers &&
        <div><h3>Composer{result.doc.composers.length > 1 ? 's' : ''}:</h3> {renderWithHighlights(result, 'composers', { forceSingleLine: true })}</div>
      }
      {
        result.fields.tuneNames &&
        <div><h3>Tune Name{result.doc.tuneNames.length > 1 ? 's' : ''}:</h3> {renderWithHighlights(result, 'tuneNames', { forceSingleLine: true })}</div>
      }
      {
        result.fields.verse1 &&
        <div><h3>Verse 1:</h3><br />{renderWithHighlights(result, 'verse1')}</div>
      }
      {
        result.fields.verse2 &&
        <div><h3>Verse 2:</h3><br />{renderWithHighlights(result, 'verse2')}</div>
      }
      {
        result.fields.verse3 &&
        <div><h3>Verse 3:</h3><br />{renderWithHighlights(result, 'verse3')}</div>
      }
      {
        result.fields.verse4 &&
        <div><h3>Verse 4:</h3><br />{renderWithHighlights(result, 'verse4')}</div>
      }
      {
        result.fields.verse5 &&
        <div><h3>Verse 5:</h3><br />{renderWithHighlights(result, 'verse5')}</div>
      }
      {
        result.fields.verse6 &&
        <div><h3>Verse 6:</h3><br />{renderWithHighlights(result, 'verse6')}</div>
      }
      {
        result.fields.verse7 &&
        <div><h3>Verse 7:</h3><br />{renderWithHighlights(result, 'verse7')}</div>
      }
      {
        result.fields.verse8 &&
        <div><h3>Verse 8:</h3><br />{renderWithHighlights(result, 'verse8')}</div>
      }
      {
        result.fields.verse9 &&
        <div><h3>Verse 9:</h3><br />{renderWithHighlights(result, 'verse9')}</div>
      }
      {
        result.fields.verse10 &&
        <div><h3>Verse 10:</h3><br />{renderWithHighlights(result, 'verse10')}</div>
      }
      {
        result.fields.chorus &&
        <div><h3>Chorus:</h3><br />{renderWithHighlights(result, 'chorus')}</div>
      }
      {
        result.fields.refrain &&
        <div><h3>Refrain:</h3><br />{renderWithHighlights(result, 'refrain')}</div>
      }
      {
        result.fields.coda &&
        <div><h3>Coda:</h3><br />{renderWithHighlights(result, 'coda')}</div>
      }
      {
        result.fields.sanctus &&
        <div><h3>Sanctus:</h3><br />{renderWithHighlights(result, 'sanctus')}</div>
      }
      {
        result.fields.bridge &&
        <div><h3>Bridge:</h3><br />{renderWithHighlights(result, 'bridge')}</div>
      }
    </li>
  )

  function renderWithHighlights(result, fieldName, options) {
    const field = result.doc[fieldName];
    const highlights = result.highlights[fieldName];

    if (Array.isArray(field)) {
      return renderArrayWithHighlights(field, highlights, options);
    }

    if (typeof (result.doc[fieldName]) === 'string') {
      return renderLineWithHighlights(field, highlights);
    }
  }

  function renderArrayWithHighlights(values, highlightsForValues, options = {}) {
    if (!highlightsForValues) {
      return values;
    }

    if (options.forceSingleLine) {
      return values.map((_, i) => {
        const htmlArray = [];
        if (i > 0) {
          htmlArray.push(', ');
        }
        htmlArray.push(renderLineWithHighlights(values[i], highlightsForValues[i]));
        return htmlArray;
      });
    }

    return values.map((_, i) => {
      return <div key={i}>{renderLineWithHighlights(values[i], highlightsForValues[i])}</div>;
    });
  }

  function renderLineWithHighlights(line, highlights) {
    if (!highlights || highlights.length === 0) {
      return line;
    }

    let i = 0;
    const ranges = [];

    highlights.forEach(highlight => {
      // add non-highlighted range before the highlight
      if (i < highlight.startIndex) {
        ranges.push({startIndex: i, endIndex: highlight.startIndex, /* kind: undefined */});
      }

      // add the highlighted range
      ranges.push(highlight);

      // update i
      i = highlight.endIndex;
    });

    // add any non-highlighted text at the end of the string
    if (i < line.length) {
      ranges.push({startIndex: i, endIndex: line.length, /* kind: undefined */});
    }

    // Create the HTML for the line, highlighting words which were matched
    const htmlArray = ranges.map((range, i) => {
      if (range.kind === 'phrase') {
        return <em key={i}><em>{line.substring(range.startIndex, range.endIndex)}</em></em>;
      }

      if (range.kind === 'token') {
        return <em key={i}>{line.substring(range.startIndex, range.endIndex)}</em>;
      }

      return line.substring(range.startIndex, range.endIndex);
    });

    return htmlArray;
  }
}

export default SearchResult
