// These are defined here (and not in lib/elisionDetector.js) because this code must be sent to the browser.
const elisions = {
  "calv'ry": 'calvary',
  "e'en": 'even',
  "e'er": 'ever',
  "fill'd": 'filled',
  "flow'r": 'flower',
  "flow'rs": 'flowers',
  "giv'n": 'given',
  "hast'ning": 'hastening',
  "heav'n": 'heaven',
  "heav'nly": 'heavenly',
  "heav'ns": 'heavens',
  "heav'nward": 'heavenward',
  "i'd": 'i would',
  "i'll": 'i will',
  "i'm": 'i am',
  "i've": 'i have',
  "jesus'": 'jesus',
  "ling'ring": 'lingering',
  "list'ning": 'listening',
  "ne'er": 'never',
  "num'rous": 'numerous',
  "o'er": 'over',
  "off'ring": 'offering',
  "pow'r": 'power',
  "pow'rs": 'powers',
  "rev'rence": 'reverence',
  "ris'n": 'risen',
  "sev'n": 'seven',
  "shad'wing": 'shadowing',
  "sinners'": 'sinners',
  "sov'reign": 'sovereign',
  "spread'st": 'spreadest',
  "suff'ring": 'suffering',
  "t'wards": 'towards',
  "th'": 'the',
  "tho'": 'though',
  "thro'": 'through',
  "tow'r": 'tower',
  "trav'ler": 'traveler',
  "vict'ry": 'victory',
  "wand'rer": 'wanderer',
  "wand'ring": 'wandering',
  "we'll": 'we will',
  "whate'er": 'whatever',
  "where'er": 'wherever',
  "th'eternal": 'eternal'
}

// Add all of the above definitions _without_ the apostrophe so that people can search by elision without typing the apostrophe.
Object.keys(elisions).forEach(key => {
  elisions[key.replace(/'/g, '')] = elisions[key];
});

module.exports = elisions;
